import { ReactNode } from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import ProjectCard from '../components/project_card';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IService, ISubService } from '../data/services';
import { IProject } from '../data/projects';
import { useAppContext } from '../context/context';


function OurServicesDetails() {
  const { service_id } = useParams();
  const { services, projects } = useAppContext()
  const [selectedService, setSelectedService] = useState<IService>();
  const [serviceProjects, setServiceProjects] = useState<IProject[]>([]);

  useEffect(() => {
    setSelectedService(services.find((service: IService) => service.id.toString() === service_id));
    setServiceProjects(projects.filter(p => String(p.service_id) === service_id));
  }, [service_id, services]);
  return selectedService ? (
    <div>
      <Header transparent={false} />
      <main className='mx-4 md:mx-16 mb-10'>
        <div className='mt-10 grid grid-cols-1 text-black'>
          <div className='grid md:grid-cols-2 gap-x-20 mb-10'>
            <div className='grid grid-cols-1 gap-y-5 my-4'>
              <h2 className='font-semibold text-3xl'>{selectedService.title}</h2>
              <p className='text-sm'>{selectedService.subtitle}</p>
            </div>
            <div className='my-4'>
              <h4 className='mb-4'>Subcategories</h4>
              <div className='grid md:grid-cols-2 gap-y-3 text-sm md:text-xs w-full font-bold'>
                {selectedService.subcategories?.map((subCategory: string, i: number) => {
                  return <p key={i}>{subCategory}</p>
                })
                }
              </div>
            </div>
          </div>
          {selectedService.sub_services?.map((sub_service: ISubService, i: number) => {
            return <Service key={i} image={sub_service.image} header={sub_service.header}>
              {sub_service.description}
            </Service>
          })}
        </div>
        <div className='grid grid-cols-1 gap-y-5'>
          <h2 className='text-3xl font-semibold w-full'>View Projects</h2>
          <hr />
          <div className='grid grid-cols-1 md:grid-cols-4'>
            {serviceProjects.map((p, i) => <ProjectCard key={i} project={p} />)}
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  ) : <></>
}
interface ServiceProp {
  children: ReactNode;
  header: string;
  image?: string;
}
function Service({ children, header, image }: ServiceProp) {
  return <div className='md:flex md:gap-x-5 my-5'>
    <div className='md:basis-1/2 grid grid-cols-1 mb-10 place-self-start'>
      <h2 className='font-semibold pb-5'>{header}</h2>
      {children}
    </div>
    {image ? <img className='h-96 rounded-sm' src={image} alt='' /> : <section className='md:basis-1/2 w-full bg-gray-200 rounded-sm h-96' />}
  </div>

}
export default OurServicesDetails;

interface Prop {
  imageUrl: string;
  name: string;
  title: string;
  onClick: () => void
}

function PeopleCard({ imageUrl, name, title, onClick}: Prop) {
  return (
    <div className='text-center p-4 md:p-8 cursor-pointer' onClick={onClick}>
      <img className='rounded-full m-auto transition-all ease-in duration-75 delay-300 mb-4 hover:outline hover:outline-offset-2 hover:outline-slate-600 hover:brightness-125' src={imageUrl} alt="Bello Aminu" />
      <h1 className='md:text-xl text-lg'>{name}</h1>
      <p className='text-gray-500 text-sm md:text-base'>{title}</p>
    </div>
  )
}

export default PeopleCard;

import Footer from '../components/footer';
import HeaderCard from '../components/header_card';
import projectsbg from '../images/projectsbg.png';
import projectstitle from '../images/projectstitle.png';
import ProjectCard from '../components/project_card';
import { useEffect, useState } from "react";
import { IProject } from '../data/projects';
import Search from "../components/search";
import { useAppContext } from '../context/context';

function Projects() {
  const { projects: allProjects } = useAppContext();
  const [projects, setProjects] = useState<IProject[]>([]);

  const search = (query: string) => {
    query = query.toLowerCase().trim();
    if (query) {
      setProjects(projects.filter(p => p.topfooter.toLowerCase().includes(query) ||
        p.bottomfooter.toLowerCase().includes(query) ||
        p.service?.toLowerCase().includes(query)));
    } else {
      setProjects(allProjects);
    }
  }

  useEffect(function () {
    setProjects(allProjects);
  }, [allProjects])

  return (
    <div>
      <header>
        <HeaderCard bg_image={projectsbg} titleimg={projectstitle} paragragh={
          'Always aiming to provide efficient and effective services to our clients and to deliver each project within budget and agreed time frame'
        } />
      </header>
      <section className='mx-4 md:mx-16 pb-10'>
        {/* <div className='sticky top-0 z-50 bg-white'> */}
        <div className='flex flex-col md:flex-row justify-between py-4'>
          <h3 className='md:text-4xl text-2xl font-semibold block text-black'>
            Our Projects
          </h3>
          <div className="lg:w-72">
            <Search placeholder="Search" search={search} />
          </div>
          {/* <ProjDropdown name={'Category'} />
						<ProjDropdown name={'Status'} />
						<ProjDropdown name={'Duration'} />
						<ProjDropdown name={'Location'} />
						<ProjDropdown name={'Date'} /> */}
        </div>
        <hr className='py-4' />
        {/* </div> */}
        <div className='grid grid-cols-2 md:grid-cols-4 gap-5'>
          {projects.map((project, k) => <ProjectCard key={k} project={project} />)}
        </div>
      </section>
      <footer>
        <Footer />
      </footer>
    </div>

  );
}

export default Projects;

import { useEffect, useState } from "react";
import Footer from '../components/footer';
import HeaderCard from '../components/header_card';
import nwsevnts from '../images/nwsevnts.png';
import nwsevntsbg from '../images/nwsevntsbg.png';
import RadioCard from '../components/radio_card';
import NewsCard from '../components/news_card';
import Search from '../components/search';
import { IPost } from '../data/news';
import { useAppContext } from "../context/context";

function Posts() {
  const { posts: allPosts } = useAppContext();
  const [selectedFilter, setSelectedFilter] = useState<'all' | 'vlog' | 'blog'>('all');
  const [posts, setPosts] = useState<IPost[]>(allPosts);

  function filterChange(e: any) {
    if (e.target.value === 'on') {
      setSelectedFilter(e.target.id);
    }
  }

  const search = (query: string) => {
    query = query.toLowerCase().trim();
    if (query) {
      setPosts(allPosts.filter(n => n.title.toLowerCase().includes(query)));
    } else {
      setPosts(allPosts);
    }
  }

  useEffect(() => {
    setPosts(allPosts);
  }, [allPosts]);

  return (
    <div>
      <header>
        <HeaderCard
          bg_image={nwsevntsbg}
          titleimg={nwsevnts}
          paragragh={'Stay up to date with the latest news and development related to A4 Associates Limited'} />
      </header>
      <section className='mx-4 md:px-16'>
        {/* <div className='sticky top-0 z-50 bg-white'> */}
        <div className='flex justify-between flex-wrap py-5 items-center'>
          <div className="my-2">
            <Search placeholder='Search Title' search={search} />
            {/* <ProjDropdown name={'Date'} /> */}
          </div>
          <div className='flex gap-2 flex-wrap my-2'>
            <RadioCard defaultChecked={true} onChange={filterChange} id='all' name='search' label={'All'} />
            <RadioCard onChange={filterChange} id='vlog' name='search' label={'Vlog/Documentary'} />
            <RadioCard onChange={filterChange} id='blog' name='search' label={'Blogs'} />
          </div>
        </div>
        <hr className='py-4' />
        <div className=''>
          <div className='grid grid-cols-1 md:grid-cols-3 justify-between gap-4 md:gap-5 lg:gap-10'>
            {posts?.filter(n => selectedFilter === 'all' || selectedFilter === n.type).map((n, i) => {
              return n.type === 'blog' ?
                <NewsCard key={i} post={n} />
                : <video key={i} className='w-full h-full rounded-lg border border-neutral-400' controls>
                  <source
                    src={n.image}
                    type="video/mp4"
                  />
                </video>
            }
            )}
          </div>
          <div className='pt-7 pb-16'>
            <div className='float-right flex justify-between w-44 text-white'>
              <p className='px-2 py-1 transition ease-in-out duration-300 delay-100 cursor-pointer hover:bg-yellow-500 focus:bg-slate-800 rounded-md bg-slate-800'>◂</p>
              <p className='px-3 py-1 transition ease-in-out duration-300 delay-100 cursor-pointer hover:bg-yellow-500 focus:bg-slate-800 rounded-md bg-yellow-500'>1</p>
              <p className='px-3 py-1 transition ease-in-out duration-300 delay-100 cursor-pointer hover:bg-yellow-500 focus:bg-slate-800 rounded-md bg-slate-800'>2</p>
              <p className='px-3 py-1 transition ease-in-out duration-300 delay-100 cursor-pointer hover:bg-yellow-500 focus:bg-slate-800 rounded-md bg-slate-800'>3</p>
              <p className='px-2 py-1 transition ease-in-out duration-300 delay-100 cursor-pointer hover:bg-yellow-500 focus:bg-slate-800 rounded-md bg-slate-800'>▸</p>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Posts;

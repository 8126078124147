import {
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import axios, { AxiosRequestConfig } from "axios";
import { IPost, IP } from "../data/news";
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { IProject, IApiProject } from "../data/projects";
import { IApiService, IService } from "../data/services";
import { Description } from "@headlessui/react/dist/components/description/description";
import { Person, IApiPeople } from "../data/people";

export const AppContext = createContext(
  {} as {
    posts: IPost[];
    projects: IProject[];
    services: IService[];
    people: Person[];
  }
);

export const useAppContext = () => useContext(AppContext);

interface Props {
  children: React.ReactNode;
}

export default function AppProvider({ children }: Props) {
  const [posts, setPosts] = useState<IPost[]>([]);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [services, setServices] = useState<IService[]>([]);
  const [people, setPeople] = useState<Person[]>([]);

  function sendRequest(method: string, url: string, data: any) {
    var options: AxiosRequestConfig = {
      method: method,
      url: url,
    };

    if (data) {
      ;
      options.data = data;
    }

    var customInstance = axios.create();
    // Add a request interceptor
    customInstance.interceptors.request.use(function (config) {
      // Do something before request is sent
      config.headers["Authorization"] = `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`;
      //config.headers["Access-Control-Allow-Origin"] = "*";
      //config.headers["Access-Control-Allow-Headers"] = "Content-Type";
      config.baseURL = process.env.REACT_APP_BASE_URL;
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
    return customInstance(options)
  }

  async function getPosts() {
    const res = await sendRequest("GET", "/api/posts?populate=*", null);
    return res.data.data.map((d: IP) => {
      return {
        id: `${d.id}`,
        body: <BlocksRenderer content={d.attributes.body} />,
        title: d.attributes.title,
        date: d.attributes.publishedAt,
        image: d.attributes.image.data ? process.env.REACT_APP_BASE_URL + d.attributes.image.data.attributes.url : "",
        type: "blog",
        author: "",
      } as IPost;
    });
  }

  async function getProjects() {
    const res = await sendRequest("GET", "/api/projects?populate=*", null);
    return res.data.data.map((d: IApiProject) => {
      const p: IProject = {
        id: d.id,
        service_id: d.attributes.service.data.id,
        service: d.attributes.service.data.attributes.title,
        topfooter: d.attributes.title,
        bottomfooter: d.attributes.subtitle,
        // category: d.attributes.category,
        location: d.attributes.location,
        duration: d.attributes.duration,
        start_date: d.attributes.start,
        status: d.attributes.status,
        assignment: <BlocksRenderer content={d.attributes.assignment} />,
        description: d.attributes.description,
        image_url: d.attributes.images.data && d.attributes.images.data.length ? process.env.REACT_APP_BASE_URL + d.attributes.images.data[0].attributes.url : "",
        images: d.attributes.images?.data.map(i => process.env.REACT_APP_BASE_URL + i.attributes.url)
      };
      return p;
    });
  }

  async function getServices() {
    const res = await sendRequest("GET", "/api/services?populate[service_type][populate][0]=pic&populate[subcategories][populate][0]=category", null);
    return res.data.data.map((d: IApiService) => {
      return {
        id: d.id,
        title: d.attributes.title,
        subtitle: d.attributes.subcontext,
        subcategories: d.attributes.subcategories.map(s => s.category),
        sub_services: d.attributes.service_type.map(s => {
          return (
            {
              image: s.pic.data ? process.env.REACT_APP_BASE_URL + s.pic.data.attributes.url : "",
              description: <BlocksRenderer content={s.description} />,
              header: s.title
            }
          )
        })
      } as IService;
    });
  }

  async function getPeople() {
    const res = await sendRequest("GET", "/api/peoples?populate=*", null);
    return res.data.data.map((d: IApiPeople) => {
      return {
        id: d.id,
        name: d.attributes.Name,
        title: d.attributes.Title,
        image: process.env.REACT_APP_BASE_URL + d.attributes.Profile.data.attributes.url,
        description: d.attributes.Bio ? <BlocksRenderer content={d.attributes.Bio} />: null,
      } as Person;
    });
  }

  useEffect(() => {
    getPosts().then(setPosts);
    getServices().then(setServices);
    getProjects().then(setProjects);
    getPeople().then(setPeople);
  }, []);

  return (
    <AppContext.Provider value={{ posts, projects, services, people }}>
      {children}
    </AppContext.Provider>
  );
}


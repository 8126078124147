import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import a4logo from '../images/a4logo.png';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useAppContext } from '../context/context';

interface SubMenu {
  name: string;
  url: string;
}

function Header({ transparent }: { transparent?: boolean }) {
  const { services } = useAppContext();
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const [serviceMenu, setServiceMenu] = useState<SubMenu[]>([])

  useEffect(function() {
    setServiceMenu(services.map(s => ({ name: s.title, url: `/services/${s.id}` })));
  }, [services])

  return (
    <div className={`relative group hover:bg-white duration-500 ${transparent ? "" : " bg-white "} ${showMobileSidebar ? " bg-white " : ""}`}>
      <div className='peer flex flex-row w-full h-24 justify-between text-white items-center transition ease-in bg-transparent  font-bold py-4 px-4 md:px-16'>
        <div className='flex h-16 items-center'>
          <img src={a4logo} className='h-full mr-2' alt='a4 logo' />
          <span className={`hidden md:inline-block font-bold text-base ${transparent ? " group-hover:text-green-600 " : " text-green-600 "}`}>A4 Associates Consulting <br />Engineers Limited</span>
        </div>
        <div className={`space-x-8 hidden md:inline-block ${!transparent ? " text-black " : " group-hover:text-black "} `}>
          <Link to="/" className={`hover:text-yellow-500 transition ease-in-out duration-500 inline-block font-normal text-sm`}>Home</Link>
          <Link to="/aboutus" className='hover:text-yellow-500 transition ease-in-out duration-500 inline-block font-normal text-sm'>About us</Link>
          <div className='hover:text-yellow-500 transition ease-in-out duration-500 inline-block font-normal text-sm'>
            <Popover className="relative">
              <Popover.Button className={`inline-flex items-center gap-x-1 text-sm leading-6 ${!transparent ? " text-black " : " group-hover:text-black "}`}>
                <span className='flex justify-between items-center gap-1 hover:text-yellow-500 transition ease-in-out duration-500'>
                  Our services
                  <svg
                    xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" strokeWidth={1.5}
                    stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                  </svg>
                </span>

              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                  <div className="w-[650px] grid grid-cols-2 shrink rounded bg-white p-4 text-xs font-semibold leading-6 text-gray-900 shadow-lg">
                    {serviceMenu.map((s, i) => (
                      <Link key={i} to={s.url} className="block p-2 transition ease-in-out duration-200 hover:text-yellow-500">
                        {s.name}
                      </Link>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
          <Link to="/projects" className='hover:text-yellow-500 transition ease-in-out duration-500 inline-block font-normal text-sm'>Our projects</Link>
          <Link to="/people" className='hover:text-yellow-500 transition ease-in-out duration-500 inline-block font-normal text-sm'>Our people</Link>
          <Link to="/posts" className='hover:text-yellow-500 transition ease-in-out duration-500 inline-block font-normal text-sm'>News & Events</Link>
        </div>
        <Link to={'/contactus'}>
          <button className={`hidden md:inline-block bg-transparent h-10 px-2 transition-all ease-in-out duration-300 hover:rounded-sm focus:bg-yellow-600 hover:bg-yellow-500 rounded-lg font-normal text-sm  ${transparent ? " group-hover:text-black group-hover:ring-black ring-white " : " ring-black text-black "} ring-1 `}>
            Contact Us
          </button>
        </Link>
        {/* the tabindex is used to make the element focusable so that the animations works properly */}
        <button tabIndex={0} className="md:hidden " onClick={() => { setShowMobileSidebar(!showMobileSidebar) }}>
          <div className="relative flex overflow-hidden items-center justify-center rounded-full w-[50px] h-[50px] transform transition-all duration-200">
            <div className="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden">
              <div className={"bg-yellow-500 h-[2px] w-8 transform transition-all duration-300 origin-left" + (showMobileSidebar ? " translate-x-10" : "")}></div>
              <div className={"bg-yellow-500 h-[2px] w-8 rounded transform transition-all duration-300" + (showMobileSidebar ? " translate-x-10 delay-75" : "")}></div>
              <div className={"bg-yellow-500 h-[2px] w-8 transform transition-all duration-300 origin-left" + (showMobileSidebar ? " translate-x-10 delay-150" : "")}></div>

              <div className={"absolute flex  items-center justify-between transform transition-all duration-500 top-2.5 " + (showMobileSidebar ? " translate-x-0  w-12" : " -translate-x-10 w-0")}>
                <div className={"absolute bg-yellow-500 h-[2px] w-5 transform transition-all duration-500 rotate-0 delay-300" + (showMobileSidebar ? " rotate-45" : "")}></div>
                <div className={"absolute bg-yellow-500 h-[2px] w-5 transform transition-all duration-500 -rotate-0 delay-300" + (showMobileSidebar ? " -rotate-45" : "")}></div>
              </div>
            </div>
          </div>
        </button>
      </div >
      <div className={`w-full bg-white ${showMobileSidebar ? "" : "hidden"}`}>
        <SideBarMenuItem to="/">Home</SideBarMenuItem>
        <SideBarMenuItem to="/aboutus">About Us</SideBarMenuItem>
        <SideBarMenuItem to="#" submenu={serviceMenu}>Our Services</SideBarMenuItem>
        <SideBarMenuItem to="/projects">Our Projects</SideBarMenuItem>
        <SideBarMenuItem to="/people">Our People</SideBarMenuItem>
        <SideBarMenuItem to="/posts">News & Events</SideBarMenuItem>
        <div className='p-4'>
          <Link to={'/contactus'}>
            <button className='bg-yellow-500 min-w-28 h-10 px-2 rounded-lg font-normal text-sm text-black ring-1 ring-white'>
              Contact Us
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

interface SidebarMenuItemProp {
  children: React.ReactNode;
  to: string;
  submenu?: SubMenu[];
}
function SideBarMenuItem({ children, to, submenu }: SidebarMenuItemProp) {
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  return <div onClick={() => { setShowSubMenu(!showSubMenu) }} className='border-b-gray-500 border-b-2 py-3 px-5  block group'>
    <Link to={to} className='uppercase'>{children}</Link>
    {submenu && showSubMenu ? <div className='pl-2 grid grid-cols-1 gap-3 mt-2 text-gray-500'>
      {submenu.map((s) => <Link to={s.url} className=''>&gt; {s.name}</Link>)}
    </div> : ""}
  </div>
}

export default Header;

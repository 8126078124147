import { ChangeEventHandler } from "react";

interface prop {
  label: string;
  name: string;
  id: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  defaultChecked?: boolean;
}

function RadioCard({ label, name, id, onChange, defaultChecked }: prop) {
  return (
    <label htmlFor={id} className='cursor-pointer flex items-center justify-between w-fit h-10 rounded-lg bg-black p-4 text-white'>
      <input defaultChecked={defaultChecked} onChange={onChange} id={id} name={name} type='radio' className='accent-white cursor-pointer' />
      <p className="px-2">{label}</p>
    </label>
  )
}

export default RadioCard;

import { Link } from "react-router-dom";
import { IProject } from "../data/projects";


interface Prop {
  project: IProject;
}

function ProjectCard({ project }: Prop) {
  const ProjectStyle = {
    '--image-url': `url(${project.image_url})`
  } as React.CSSProperties;
  return (
    <Link to={`/projects/${project.id}`}>
      <div
        style={ProjectStyle}
        className={`bg-[image:var(--image-url)] bg-cover bg-no-repeat rounded-lg ` +
          ` overflow-hidden min-h-40 md:min-h-60 transition delay-200 hover:brightness-90 ease-in-out ` +
          ` hover:scale-105 duration-300 h-full w-full relative`}>
        <h3 className='text-white capitalize text-xs md:text-sm p-2 bg-yellow-500 w-fit rounded-br-lg'>{project.service}</h3>
        <p className='text-white text-xs md:text-sm p-2 font-normal bg-gradient-to-b from-transparent to-black rounded-b-lg absolute bottom-0 w-full'>
          {project.topfooter}<br />{project.bottomfooter}
        </p>
      </div>
    </Link>
  )
}

export default ProjectCard;

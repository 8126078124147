import './App.css';
import tractor from './images/a4tractor.png';
import arrowright from './images/arrowright.png';
import bopp from './images/bopp.jpg';
import coarms from './images/coarms.jpg';
import buag from './images/buag.jpg';
import ubed from './images/ubed.jpg';
import shem from './images/shem.jpg';
import npc from './images/npc.jpg';
import npfrc from './images/npfrc.jpg';
import ProjectCard from './components/project_card';
import Footer from './components/footer';
import Header from './components/header';
import ClientCard from './components/client_card';
import { Link } from 'react-router-dom';
import { useAppContext } from './context/context';

function App() {
  const { projects } = useAppContext()

  return (
    <div className="App">
      <div className={`bg-[url('./images/Landingpic1.png')] bg-no-repeat bg-cover w-full h-auto `}>
        <Header transparent={true} />
        <div className='px-4 md:px-20 flex-row md:space-y-10 py-20 w-full md:w-2/3'>
          <h4 className='text-white text-lg'>Welcome to A4 Associates Consulting Engineers Limited.</h4>
          <hr className='hidden md:inline-block w-full bg-transparent bg-clip-text' />
          <h1 className='md:text-5xl text-2xl text-white font-medium'>Your Gateway to Excellence in Consulting and Engineering!</h1>
          <div className='md:flex hidden space-x-8 items-center h-38'>
            <h4 className='text-lg font-light text-white'>We’ve Worked with numerous clients to bring their projects to life.</h4>
            <div className='flex items-center space-x-8 text-white'>
              <div className='flex flex-col -space-y-7'><p className='font-semibold text-7xl md:text-6xl'>221</p><br /><h2 className='text-xs text-right text-yellow-500'>CLIENTS</h2></div>
              <div className='flex flex-col -space-y-7'><p className='font-semibold text-7xl md:text-6xl'>521</p><br /><h2 className='text-xs text-right text-yellow-500'>PROJECTS</h2></div>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        {/* PROJECTS */}
        <div className='px-4 md:px-20 md:hidden text-black space-x-8 items-center pb-14'>
          <h4 className='text-3xl font-semibold my-12'>We’ve Worked with numerous clients to bring their projects to life.</h4>
          <div className='flex items-center space-x-8'>
            <div className='flex flex-col -space-y-7'><p className='font-semibold text-7xl md:text-6xl'>221</p><br /><h2 className='text-xs text-right text-yellow-500'>CLIENTS</h2></div>
            <div className='flex flex-col -space-y-7'><p className='font-semibold text-7xl md:text-6xl'>521</p><br /><h2 className='text-xs text-right text-yellow-500'>PROJECTS</h2></div>
          </div>
        </div>
        {/* PROJECTS ENDS */}

        <div className="px-4 md:px-20 bg-[url('./images/meshleft.png')] bg-no-repeat bg-right bg-contain md:flex py-20">
          <div className='md:basis-1/2 p-5'>
            <div className='relative md:-mr-16 z-50' >
              <img alt="tractor" className='rounded-lg w-[90%] md:w-full h-full' src={tractor} />
            </div>
          </div>
          <div className='md:basis-1/2 p-5'>
            <div className='space-y-5 rounded-lg p-10 md:pl-32 bg-yellow-50 w-[90%] md:w-full h-full md:-ml-16 float-right md:float-none -mt-12'>
              <h2 className='text-yellow-500'>WHO WE ARE</h2>
              <h1 className='text-3xl font-semibold'>Our Mission</h1>
              <p className='text-sm block sm:text-balance'>
                A4 Associates Limited aims to provide efficient and
                effective services to our clients at affordable cost and to
                deliver each project within budget and agreed time frame.
                Our mission is to render state of the art engineering
                professional services in a way that brings joy, satisfaction
                and honour to our customers and continent.
              </p>
            </div>
            <div className='clear-both'></div>
          </div>
        </div>
        <div className="px-4 md:px-20 bg-[url('./images/meshleft.png')] bg-no-repeat bg-left bg-contain">
          <div className='flex pb-9 justify-between'>
            <h3 className='font-semibold text-2xl cursor-pointer'>Projects</h3>
            <Link to={'/projects'}>
              <h4 className='text-yellow-500 transition-all ease-in-out duration-300 hover:text-yellow-600 self-end font-semibold flex text-sm cursor-pointer'>SEE MORE
                <img alt="right arrow" className='h-5 w-5' src={arrowright} />
              </h4>
            </Link>
          </div>
          <div className='grid grid-cols-1 gap-y-5 md:grid-cols-3 md:gap-10 lg:gap-40 min-h-60 justify-between'>
            {projects.slice(0, 3).map((p, i) => <ProjectCard key={i} project={p} />)}
          </div>
          <div className='py-24'>
            <h3 className='font-semibold pb-9 text-2xl'>Existing Clients</h3>
            <div className='p-2 overflow-hidden'>
              <div className='w-full flex space-x-3'>
                <div className='flex space-x-3 animate-loop-scroll'>
                  <div className='size-32'><ClientCard imageUrl={npfrc} /></div>
                  <div className='size-32'><ClientCard imageUrl={bopp} /></div>
                  <div className='size-32'><ClientCard imageUrl={coarms} /></div>
                  <div className='size-32'><ClientCard imageUrl={buag} /></div>
                  <div className='size-32'><ClientCard imageUrl={ubed} /></div>
                  <div className='size-32'><ClientCard imageUrl={shem} /></div>
                  <div className='size-32'><ClientCard imageUrl={npc} /></div>
                </div>
                <div className='flex space-x-3 translate-x-full delay-15000 animate-loop-scroll'>
                  <div className='size-32'><ClientCard imageUrl={npfrc} /></div>
                  <div className='size-32'><ClientCard imageUrl={bopp} /></div>
                  <div className='size-32'><ClientCard imageUrl={coarms} /></div>
                  <div className='size-32'><ClientCard imageUrl={buag} /></div>
                  <div className='size-32'><ClientCard imageUrl={ubed} /></div>
                  <div className='size-32'><ClientCard imageUrl={shem} /></div>
                  <div className='size-32'><ClientCard imageUrl={npc} /></div>
                </div>
              </div>
            </div>
            {/* <div className='grid md:grid-cols-7 grid-cols-3 gap-3'>
              <div className='size-full'><ClientCard imageUrl={coatofarms} /> </div>
              <div className='size-full'><ClientCard imageUrl={bpplogo} /></div>
              <div className='size-full'><ClientCard imageUrl={npfseal} /></div>
              <div className='size-full'><ClientCard imageUrl={nnpclogo} /></div>
              <div className='size-full'><ClientCard imageUrl={hashem} /></div>
              <div className='size-full'><ClientCard imageUrl={ubecicon} /></div>
              <div className='size-full'><ClientCard imageUrl={bualogo} /></div>
            </div> */}
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}

export default App;

import certione from '../images/certione.jpg';
import certitwo from '../images/certitwo.jpg';
import certithree from '../images/certithree.jpg';
import certifour from '../images/certifour.jpg';
import certifive from '../images/certifive.jpg';
import certisix from '../images/certisix.jpg';
import certiseven from '../images/certiseven.jpg';
import certieight from '../images/certieight.jpg';
import certinine from '../images/certinine.jpg';
import certiten from '../images/certiten.jpg';
import certieleven from '../images/certieleven.jpg';
import certitwelve from '../images/certitwelve.jpg';
import certithirteen from '../images/certithirteen.jpg';
import certifourteen from '../images/certifourteen.jpg';
import certififteen from '../images/certififteen.jpg';
import certisixteen from '../images/certisixteen.jpg';
import certiseventeen from '../images/certiseventeen.jpg';
import certieighteen from '../images/certieighteen.jpg';
import certinineteen from '../images/certinineteen.jpg';
import certitwenty from '../images/certitwenty.jpg';
import certitwentyone from '../images/certitwentyone.jpg';
import certitwentytwo from '../images/certitwentytwo.jpg';
import certitwentythree from '../images/certitwentythree.jpg';
import certitwentyfour from '../images/certitwentyfour.jpg';
import certitwentyfive from '../images/certitwentyfive.jpg';
import abtus from '../images/abtus.png';
import abtusone from '../images/abtusone.png';
import abtustwo from '../images/abtustwo.png';
import abtusthree from '../images/abtusthree.png';
import abtusfour from '../images/abtusfour.png';
import people from '../images/People.png';
import profit from '../images/Profit.png';
import process from '../images/Process.png';
import product from '../images/Product.png';
import planet from '../images/Planet.png';
import partnership from '../images/Partnership.png';
import profession from '../images/Profession.png';
import gallerypic from '../images/galleryimgs.png';
import Footer from '../components/footer';
import Header from '../components/header';
import Modal from '../components/modal';
import { useState } from 'react';

function About() {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className=''>
      <header className=''>
        <div className={`bg-[url('./images/aboutus.jpg')] bg-no-repeat bg-cover bg-center w-full h-auto`}>
          <Header transparent={true} />
          <div className='md:pt-36 px-4 md:px-20 flex flex-col md:flex-row items-center justify-between gap-3 md:gap-20 pb-4'>
            <div className='flex justify-between w-full h-full'>
              <img className='w-full h-full' src={abtus} alt='about us' />
            </div>
            <section className='text-white max-w-2xl '>
              <hr className='pb-4' />
              <p>
                Always aiming to provide efficient and effective services to our
                clients and to deliver each project within budget and agreed time
                frame
              </p>
            </section>
          </div>
        </div>
      </header>
      <section className='px-4 md:px-16 lg:px-32 space-y-28 my-14'>
        <div className='text-black items-center'>
          <h3 className='text-2xl font-semibold md:text-center pb-6'>Our Background</h3>
          <div className='text-sm md:text-lg text-start md:text-center'>
            <p className=''>
              A4 Associates Limited A4A is a Nigerian firm incorporated in 2002, and owned and managed
              by a group of young and resourceful professionals. A4A offers multi-disciplinary engineering
              consultancy services to public and private sector clients within Nigeria.
            </p><br />
            <p>
              A4A has provided services in various phases of the project cycle from conception, planning, design, supervision
              of project implementation, post project evaluation and facilities management.
            </p><br />
            <p>
              A4A has over the years been engaged in various sectors of the economy offering services in highway/transportation,
              civil, structural and environmental engineering. A4 Associates Limited is also engaged in water
              resources management and information technology
            </p>
          </div>
        </div>

        <div className='flex md:flex-row flex-col-reverse items-center'>
          <div className='md:basis-1/2 items-center'>
            <section className='bg-yellow-50 -mt-10 ml-10 md:m-0 space-y-5 rounded-lg md:p-10 px-10 pb-10 pt-16 '>
              <h2 className='text-yellow-500'>WHO WE ARE</h2>
              <h1 className='text-3xl font-semibold'>Our Vision</h1>
              <p className='text-md w-full text-gray-500 leading-6'>
                Our vision is to achieve service excellence on all
                our projects and become the Premier Consulting Firm
                in Nigeria and Africa.
              </p>
            </section>
          </div>
          <div className='md:basis-1/2 z-10'>
            <div className='mr-10 md:m-0'>
              <img className='rounded-lg w-full h-full' src={abtusone} alt='about us' />
            </div>
          </div>
          <div className='clear-both'></div>
        </div>

        <div className='md:flex mb-10'>
          <div className='md:basis-1/2'>
            <div className='relative mr-10 md:m-0 z-10'>
              <img alt="people standing" className='rounded-lg w-full h-full' src={abtustwo} />
            </div>
          </div>
          <div className='md:basis-1/2'>
            <div className='bg-yellow-50 space-y-5 -mt-10 ml-10 rounded-lg md:px-24 px-10 py-12  md:-ml-16 md:-mt-10 float-right md:float-none'>
              <h2 className='text-yellow-500'>WHO WE ARE</h2>
              <h1 className='text-3xl font-semibold'>Our Mission</h1>
              <p className='text-md block sm:text-balance text-gray-500 leading-7'>
                A4 Associates Limited aims to provide efficient and
                effective services to our clients at affordable cost and to
                deliver each project within budget and agreed time frame.
                Our mission is to render state of the art engineering
                professional services in a way that brings joy, satisfaction
                and honour to our customers and continent.
              </p>
            </div>
            <div className='clear-both'></div>
          </div>
        </div>

        <div className='flex flex-col-reverse md:flex-row items-center'>
          <div className='basis-1/2'>
            <section className='bg-yellow-50 space-y-5 rounded-lg md:px-24 px-10 py-12 -mt-10 ml-10 md:m-0'>
              <h2 className='text-yellow-500'>WHO WE ARE</h2>
              <h1 className='text-3xl font-semibold'>Our Objectives</h1>
              <ul className='text-md text-gray-500 list-disc px-4 leading-6'>
                <li>
                  To satisfy the needs of our clients by providing value on
                  project investments their project investments
                </li>
                <li>
                  To keep track of progress of project monitoring through
                  project monitoring
                </li>
                <li>
                  To appraise projects through structured project
                  evaluation systems to ascertain conformity or alteration
                  from inceptive project goals
                </li>
                <li>
                  To provide adequate documentation for project to
                  facilitate their conformity and/or restraint on defaulters
                  in order to prevent loss of investments
                </li>
                <li>
                  To utilize the principle of information applicable
                  technology project from to inception to completion and
                  facility management.
                </li>
              </ul>
            </section>
          </div>
          <div className='basis-1/2'>
            <div className='relative mr-10 md:m-0 md:-ml-10 z-10'>
              <img className='rounded-lg w-fit h-fit' src={abtusthree} />
            </div>
          </div>
        </div>

        <div className=''>
          <div className='flex flex-col md:flex-row mr-10 md:m-0'>
            <div className='basis-1/2'>
              <div className='relative  z-10' >
                <img alt="tractor" className='rounded-lg w-full h-full' src={abtusfour} />
              </div>
            </div>
            <div className='basis-1/2'>
              <div className='bg-yellow-50 space-y-5 w-full rounded-lg ml-10 px-8 py-14 md:pl-32 md:-ml-16 -mt-12'>
                <h1 className='text-3xl font-semibold'>Our Qualification</h1>
                <p className='text-md text-gray-500 '>
                  At A4 Associates Ltd, we take pride in our team of highly
                  qualified and experienced professionals who are dedicated
                  to pushing the boundaries of innovation. Our engineers
                  and designers are armed with a wealth of knowledge and
                  skills, ensuring that we consistently deliver projects that
                  exceed expectations.
                </p>
                <button
                  onClick={() => {
                    setOpen(true);
                  }}
                  className='py-3 px-4 bg-yellow-500 transition ease-in duration-300 hover:bg-yellow-600 text-sm text-black font-bold rounded-lg'>
                  View certificates
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL */}
        <Modal className="bg-transparent" open={open} toggleOpen={(v: boolean) => setOpen(v)}>
          <div className='flex flex-row gap-3 overflow-scroll items-center'>
            <img src={certione} alt='' className='md:h-96 md:w-96' />
            <img src={certitwo} alt='' className='md:h-96 md:w-96' />
            <img src={certithree} alt='' className='md:h-96 md:w-96' />
            <img src={certifour} alt='' className='md:h-96 md:w-96' />
            <img src={certifive} alt='' className='md:h-96 md:w-96' />
            <img src={certisix} alt='' className='md:h-96 md:w-96' />
            <img src={certiseven} alt='' className='md:h-96 md:w-96' />
            <img src={certieight} alt='' className='md:h-96 md:w-96' />
            <img src={certinine} alt='' className='md:h-96 md:w-96' />
            <img src={certiten} alt='' className='md:h-96 md:w-96' />
            <img src={certieleven} alt='' className='md:h-96 md:w-96' />
            <img src={certitwelve} alt='' className='md:h-96 md:w-96' />
            <img src={certithirteen} alt='' className='md:h-96 md:w-96' />
            <img src={certifourteen} alt='' className='md:h-96 md:w-96' />
            <img src={certififteen} alt='' className='md:h-96 md:w-96' />
            <img src={certisixteen} alt='' className='md:h-96 md:w-96' />
            <img src={certiseventeen} alt='' className='md:h-96 md:w-96' />
            <img src={certieighteen} alt='' className='md:h-96 md:w-96' />
            <img src={certinineteen} alt='' className='md:h-96 md:w-96' />
            <img src={certitwenty} alt='' className='md:h-96 md:w-96' />
            <img src={certitwentyone} alt='' className='md:h-96 md:w-96' />
            <img src={certitwentytwo} alt='' className='md:h-96 md:w-96' />
            <img src={certitwentythree} alt='' className='md:h-96 md:w-96' />
            <img src={certitwentyfour} alt='' className='md:h-96 md:w-96' />
            <img src={certitwentyfive} alt='' className='md:h-96 md:w-96' />
          </div>
        </Modal>
        {/* END MODAL */}
        <div className='grid grid-cols-2 gap-5 md:grid-cols-4 md:gap-14 items-start w-full'>
          <div className='w-full h-fit p-5'>
            <h4 className='font-semibold '>Our 7 Ps</h4>
            <p className='text-xs pt-2'>We believe and devote our attention to these resources:</p>
          </div>
          {/* remember to create a function for the below elements */}
          <OurPs img={people} header="People" body="we invest in our people as people make the difference" />
          <OurPs img={profit} header="Profit" body="we do recognize the need for sustainability" />
          <OurPs img={process} header="Process" body="we seek and utilize state of the art technologies" />
          <OurPs img={product} header="Product" body="we strive to render excellent services always trying to surpass our clients expectations" />
          <OurPs img={planet} header="Planet" body="we think responsibly, making sure our products and services are environment friendly" />
          <OurPs img={partnership} header="Partnership" body="we partner with our staff, clients, shareholders, stakeholders and the general public" />
          <OurPs img={profession} header="Profession" body="we are deeply passionate about our engineering profession, we celebrate it, we give it our best and we conduct our affairs professionally at all times." />
        </div>
      </section>
      <div className='grid grid-cols-1 p-5 bg-black'>
        <h2 className='text-white font-semibold text-4xl mx-14 my-8'>Office Gallery</h2>
        <img className='place-self-center' src={gallerypic} />
      </div>
      <Footer />
    </div>
  );
}

function OurPs({ img, header, body }: { img: string; header: string; body: string }) {
  return <div className='w-full h-fit ring-1 ring-gray-400 rounded-lg pt-1 pb-4 px-2 md:px-4 transition ease-in-out duration-500 hover:bg-yellow-500 hover:scale-105'>
    <img className='rounded-full w-6 h-6 float-right' src={img} alt={header} />
    <h4 className='cursor-default	font-semibold pt-5 text-sm md:text-base'>{header}</h4>
    <p className='cursor-default text-xs pt-2'>
      {body}
    </p>
  </div>
}

export default About;

import { useState } from 'react';
import Footer from '../components/footer';
import HeaderCard from '../components/header_card';
import Modal from '../components/modal';
import PeopleCard from '../components/people_card';
import ourppl from '../images/ourppl.png';
import ourpplbg from '../images/ourpplbg.png';
import flagNg from '../images/NG.png';
import { Person } from '../data/people';
import { useAppContext } from '../context/context';

function People() {
  const { people } = useAppContext();
  const [open, setOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<Person>(people[0]);

  return (
    <div className=''>
      <header className=''>
        <HeaderCard
          bg_image={ourpplbg}
          titleimg={ourppl}
          paragragh={
            'As a dynamic and dedicated team, we pride ourselves on our unwavering' +
            ' commitment to delivering unparalleled solutions. With a wealth of experience' +
            ' and a passion for innovation, the A4 team is driven by the pursuit of excellence' +
            ' in every project we undertake.'}
        />
      </header>
      <main className='' >
        {/* MODAL */}
        <Modal className="mt-36 bg-yellow-50" open={open} toggleOpen={(v: boolean) => setOpen(v)}>
          {selectedPerson ?
            <section className='relative p-8 text-sm text-gray-700'>
              <div className='w-full -mt-52'>
                <img src={selectedPerson.image} alt="" className='w-52 rounded-full m-auto border-white border-4' />
              </div>
              <div className='flex justify-between'>
                <div>
                  <h1 className='text-2xl '>{selectedPerson.name}</h1>
                  <h1 className='text-yellow-500'>{selectedPerson.title}</h1>
                </div>
                <div>
                  <img src={flagNg} alt='flag' />
                </div>
              </div>
              <div className='my-4 space-y-4'>
                {selectedPerson.description}
                {/* {selectedPerson.description.map((d, i) => <p key={i}>{d}</p>)} */}
              </div>
            </section> : ""}
        </Modal>
        {/* END MODAL */}
        <div className='px-4 md:px-20'>
          <div className='my-8'>
            <h1 className='text-4xl'>The A4 Associates Team</h1>
            <hr className='border-t-1 border-black mt-5' />
          </div>
          <div className='my-8 grid md:grid-cols-4 grid-cols-2 gap-3'>
            {people.map((person, k) => {
              return <PeopleCard
                onClick={() => {
                  setSelectedPerson(person);
                  setOpen(true);
                }}
                key={k}
                imageUrl={person.image}
                name={person.name}
                title={person.title}
              />
            })}
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default People;

import { ChangeEvent, useState } from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import addrsmap from '../images/addrsmap.png';

interface ICategory {
  name: string;
  description: string;
  has_files?: boolean;
}

function ContactUs() {
  const [categories, setCategories] = useState<ICategory[]>([
    { name: "inquiries", description: "General Inquiries" },
    { name: "refer_job", description: "Refer a job" },
    { name: "your_job", description: "Invite us to express interest in your job" },
    { name: "inquire_our_work", description: "Inquiries regarding our work" },
    { name: "apply_employment", description: "Apply for employment as staff", has_files: true },
    { name: "apply_consulting", description: "Apply for short-term consulting engagement", has_files: true }
  ]);
  const [category, setCategory] = useState<ICategory>(categories[0]);

  function handleOnChange(e: ChangeEvent<HTMLSelectElement>) {
    const selectedCategory = categories.find(c => c.name === e.target.value);
    if (selectedCategory) setCategory(selectedCategory);
  }

  return (
    <div>
      <Header transparent={false} />
      <section className='px-4 md:px-16 md:mb-16 py-6'>
        <div className='lg:grid lg:grid-cols-2 lg:gap-x-56 space-y-10'>
          <div className='grid grid-cols-1 gap-y-5 text-black'>
            <h2 className='text-3xl font-semibold'>Contact Us</h2>
            <hr className='mb-5' />
            <img className='cursor-zoom-in' src={addrsmap} alt="address map" />
            <div>
              <h4 className='text-base font-semibold'>Abuja Address:</h4>
              <p className='text-sm'>No. 28 Arusha Crescent Wuse Zone 1, Abuja.</p>
            </div>
            <div>
              <h4 className='text-base font-semibold'>Abuja Branch Office:</h4>
              <p className='text-sm'>4 Accra Street wuse zone 2, Abuja</p>
            </div>
            <div>
              <h4 className='text-base font-semibold'>Uyo office:</h4>
              <p className='text-sm'>Plot 112 Nsikak Eduok Avenue, Uyo</p>
            </div>
            <div>
              <h4 className='text-base font-semibold'>Yola Address:</h4>
              <p className='text-sm'>
                G.I Mega plaza, No 40/42 Lamido Ahmadu <br />
                Road, Adamawa.
              </p>
            </div>
          </div>
          <div className='my-10'>
            <select onChange={handleOnChange} className='uppercase w-full rounded-lg bg-yellow-100 px-3 h-10 border border-lime-500 mb-10'>
              {categories.map((c, k) => <option className='uppercase' value={c.name} key={k}>{c.description}</option>)}
            </select>
            <h2 className='text-3xl font-semibold'>{category.description}</h2>
            <div className="flex gap-2 my-10">
              <div className='basis-1/2'>
                <Input type="text" placeholder='Name' name='name' id='name' label='Name' />
              </div>
              <div className='basis-1/2'>
                <Input type="text" placeholder='Phone' name='phone' id='phone' label='Phone' />
              </div>
            </div>
            <div className="flex my-10">
              <div className='w-full'>
                <Input type='email' placeholder='Email' name='email' id='email' label='Email' />
              </div>
              {/* <div className='basis-1/2'>
                <Input type='text' placeholder='Company' name='company' id='company' label='Company' />
              </div> */}
            </div>
            {category.has_files ? 
            <div className='my-4'>
              <Input type="file" name="docs" id="docs" label="Upload files" />
            </div>:""}
            <textarea placeholder='input message here...' className='focus:outline-lime-600 p-2 w-full border border-black rounded-md my-3' />
            <div className='text-right'>
              <button className='px-8 py-3 my-5 rounded-md transition-all ease-in-out duration-300 bg-yellow-500 hover:bg-yellow-600 text-sm font-semibold'>Submit</button>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

function Input({ name, label, id, type, placeholder }: {
  name: string;
  label: string;
  id: string;
  type: string;
  placeholder?: string;
}) {
  return (<div className="">
    <label htmlFor={name} className="">{label}</label>
    <input
      id={id}
      name={name}
      placeholder={placeholder}
      type={type}
      className="w-full rounded-md border border-black p-2 focus:outline-lime-600"
    />
  </div>)
}
export default ContactUs;

import Footer from '../components/footer';
import Header from '../components/header';
import { Link, useParams } from 'react-router-dom';
import { IProject } from '../data/projects';
import { useEffect, useState } from 'react';
import { useAppContext } from '../context/context';

function ProjectDetails() {
  const { project_id } = useParams();
  const { projects } = useAppContext()
  const [project, setProject] = useState<IProject | undefined>();
  const [nextProject, setNextProject] = useState<IProject | undefined>();
  const [previousProject, setPreviousProject] = useState<IProject | undefined>();

  useEffect(() => {
    setProject(projects.find((p) => `${p.id}` === project_id));
    setNextProject(projects.find((p) => `${p.id - 1}` === project_id));
    setPreviousProject(projects.find((p) => `${p.id + 1}` === project_id));
  }, [project_id, projects]);

  return project ? (
    <div>
      <Header transparent={false} />
      <main className=''>
        <div className='grid grid-cols-1 gap-y-12 mb-10 mx-4 md:mx-16 my-10'>
          <h3 className='text-3xl font-semibold text-black'>{project.topfooter + " " + project?.bottomfooter}</h3>
          <section className='flex justify-between flex-wrap space-y-3'>
            <div className='grid grid-cols-1'>
              <p className='text-sm text-yellow-500'>Project Category</p>
              <p className='text-base font-semibold'>{project.service}</p>
            </div>
            <div className='grid grid-cols-1'>
              <p className='text-sm text-yellow-500'>Project Location</p>
              <p className='text-base font-semibold'>{project.location}</p>
            </div>
            <div className='grid grid-cols-1'>
              <p className='text-sm text-yellow-500'>Project Duration</p>
              <p className='text-base font-semibold'>{project.duration}</p>
            </div>
            <div className='grid grid-cols-1'>
              <p className='text-sm text-yellow-500'>Project Start</p>
              <p className='text-base font-semibold'>{project.start_date}</p>
            </div>
            <div className='grid grid-cols-1'>
              <p className='text-sm text-yellow-500'>Project Status</p>
              <p className='text-base font-semibold'>{project.status}</p>
            </div>
          </section>
          <section className='md:flex text-sm md:gap-10 text-black justify-between'>
            <div className='basis-1/2'>
              <h2 className='font-bold text-md my-4'>Project Description</h2>
              <p className='text-wrap'>
                {project.description}
              </p>
            </div>
            <div className='basis-1/2'>
              <div className='text-wrap'>
                <h2 className='font-bold text-md my-4'>Our assignment on this project include:</h2>
                {project.assignment}
              </div>
            </div>
          </section>
        </div>
        <div className='bg-orange-100 p-4'>
          <section className='grid md:grid-cols-3 md:gap-4 lg:gap-6 justify-between space-y-3'>
            {project.images?.map((img, k) => <img key={k} className='w-full rounded-md' alt='' src={img} />)}
          </section>
          <div className='my-6 flex flex-wrap justify-between'>
            <div className='py-6'>
              {previousProject ?
                <div className='space-y-6'>
                  <Link
                    to={`/projects/${previousProject.id}`}
                    className='p-3 mt-5 rounded-md bg-yellow-500 hover:bg-yellow-400 text-sm font-bold'>
                    ◂ View previous project
                  </Link>
                  <p className='text-sm'>{previousProject.topfooter + " " + previousProject.bottomfooter}</p>
                </div>
                : ""}
            </div>
            <div className='py-6 w-full md:w-fit text-right'>
              {nextProject ?
                <div className='space-y-6'>
                  <Link
                    to={`/projects/${nextProject.id}`}
                    className='p-3 mt-5 rounded-md bg-yellow-500 hover:bg-yellow-400 text-sm font-bold'>
                    View next project ▸
                  </Link>
                  <p className='text-sm'>{nextProject.topfooter + " " + nextProject.bottomfooter}</p>
                </div>
                : ""}
            </div>
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  ) : <></>
}

export default ProjectDetails;

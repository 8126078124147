import { Link } from 'react-router-dom';
import { IPost } from '../data/news';
import arrow from '../images/arrow.png';


interface prop {
  post: IPost;
}

function NewsCard({ post }: prop) {
  return (
    <div className='rounded-lg shadow-md w-full h-fit transition ease-in-out duration-700 delay-300 hover:shadow-xl hover:scale-105'>
      <div className=''>
        <img className='w-full' src={post.image} alt='' />
      </div>
      <div className='grid px-5 pt-3 pb-5 gap-y-3'>
        <span className='flex items-center justify-between'>
          <Link to={`/posts/${post.id}`}>
            <img
              className='cursor-pointer transition-all ease-in-out hover:bg-yellow-600 duration-300 p-2 -mt-10 rounded-full bg-yellow-500'
              src={arrow}
              alt=''
            />
          </Link>
        </span>
        <h2 className='text-xl font-semibold text-yellow-500'>{post.title}</h2>
        <p className='text-yellow-500 text-xs'>By <b>A4 Associates Limited</b></p>
      </div>
    </div>
  )
}

export default NewsCard;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import About from './pages/about';
import People from './pages/people';
import Projects from './pages/projects';
import Posts from './pages/newsevents';
import PostDetails from './pages/newscardpage';
import ContactUs from './pages/contactus';
//import Services from './pages/services';
import Services from './pages/servicesdetails';
import ProjectDetails from './pages/projectdetails';
import AppProvider from './context/context';

function App() {
  return (
    <div className="App">
      <div>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/aboutus" element={<About />} />
            <Route path="/people" element={<People />} />
            <Route path="/projects" element={<Projects/>} />
            <Route path="/posts" element={<Posts/>} />
            <Route path="/posts/:post_id" element={<PostDetails/>} />
            <Route path="/contactus" element={<ContactUs/>} />
            <Route path="/services/:service_id" element={<Services />} />
            <Route path="/projects/:project_id" element={<ProjectDetails/>} />
          </Routes>
        </BrowserRouter>
      </div>

    </div>
  );
}

export default App;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppProvider><App /></AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from '../components/footer';
import HeaderCard from '../components/header_card';
import nwsevnts from '../images/nwsevnts.png';
import nwsevntsbg from '../images/nwsevntsbg.png';
import { IPost, news } from "../data/news";
import { useAppContext } from "../context/context";
import arrow from '../images/arrow.png';
import send from '../images/send.png';

function PostDetails() {
  const {posts} = useAppContext();
  const { post_id } = useParams();
  const [selectedPost, setSelectedPost] = useState<IPost>();

  useEffect(() => {
    setSelectedPost(posts.find((n) => n.id === post_id));
  }, [post_id, posts]);

  return (
    <div>
      <header>
        <HeaderCard
          bg_image={nwsevntsbg}
          titleimg={nwsevnts}
          paragragh={'Stay up to date with the latest news and development related to A4 Associates Limited'}
        />
      </header>
      <section className='md:mx-10 lg:mx-16 mx-8 my-10'>
        <div className='sticky top-0 z-50 bg-white pt-5 opacity-95 backdrop-blur-lg'>
          <div className='flex space-x-10 mb-5 items-center'>
            <Link to="/posts">
              <img 
                className='rotate-180 cursor-pointer transition ease-in-out hover:bg-yellow-600 duration-500 p-2 rounded-full bg-yellow-500'
                src={arrow}
                alt="alt"
              />
            </Link>
            <span className='text-sm text-slate-400'>News & Events / {selectedPost?.id}</span>
          </div>
          <hr className='pt-5' />
        </div>
        <div>
          <h2 className='font-semibold text-5xl pt-5 pb-10 text-black'>{selectedPost?.title}</h2>
          <div className="grid grid-cols-1 place-items-center text-center">
            {selectedPost?.body}
          </div>
        </div>
      </section>
      {/*
        <div className='scale-75 md:scale-100 my-16 md:mx-24 md:py-10 py-5 px-5 md:px-32 shadow-2xl grid grid-cols-1 gap-y-5 place-items-center text-black'>
          <h2 className='md:text-3xl text-xl font-semibold'>Get to know the latest with us</h2>
          <p className='text-sm'>Sign up to our newsletter</p>
          <div className='flex items-center w-full'>
            <input type='email' inputMode="email" className='bg-yellow-100 px-5 py-3 w-full placeholder-gray-500' placeholder='Enter your email' />
            <button className='bg-black py-3 px-5 text-white '>
              {/*<p className="-rotate-45"> ➤</p>/}
              <p className="">
                <img src={send} alt='' />
              </p>
            </button>
          </div>
        </div>
      </section>
      */}
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

export default PostDetails;

import Header from "./header"

interface Prop {
  bg_image: string
  titleimg: string
  paragragh: string
}

function HeaderCard({ bg_image, titleimg, paragragh }: Prop) {
  const HeaderStyle = {
    '--image-url': `url(${bg_image})`
  } as React.CSSProperties;
  /*<div style={HeaderStyle} className={`bg-[image:var(--image-url)] bg-no-repeat bg-cover w-full h-auto pb-5 mb-7`}>
        <Header transparent={true} />
        <div className='pt-36 px-16 flex justify-between'>
          <img src={titleimg} alt="about us" />
          <section className='text-white pt-44'>
            <hr className='pb-4' />
            <p>
              {paragragh}
            </p>
          </section>
        </div>
      </div>*/
  return (
    <div style={HeaderStyle} className={`bg-[image:var(--image-url)] bg-no-repeat bg-cover bg-center w-full h-auto`}>
      <Header transparent={true} />
      <div className='md:pt-36 px-4 md:px-20 flex flex-col md:flex-row items-center justify-between gap-3 md:gap-20 pb-4'>
        <div className=''>
          <img className="" src={titleimg} alt="about us" />
        </div>
        <section className='text-white md:pt-44'>
          <hr className='pb-4' />
          <p>
            {paragragh}
          </p>
        </section>
      </div>
    </div>
  )

}


export default HeaderCard;

import { useState } from "react";

function Search({ placeholder, search }: { placeholder: string, search: (v: string) => void }) {
  const [query, setQuery] = useState("");
  return (
    <div className="relative rounded-md shadow-sm">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <span className="m-auto text-3xl text-gray-400" aria-hidden="true">⌕</span>
      </div>
      <form onSubmit={e => {
        e.preventDefault();
        search(query);
      }}>
        <input
          type="text"
          name="search"
          id="search"
          className="bg-black w-full rounded-lg border-0 py-3 pl-10 text-white ring-1 ring-white placeholder:text-gray-400 focus:ring-1 focus:ring-white sm:text-sm sm:leading-6"
          placeholder={placeholder}
          onChange={({ target: { value } }) => {
            setQuery(value);
            if (!value) {
              search(value);
            }
          }}
        />
      </form>
    </div>
  )
}

export default Search;

interface Prop {
  imageUrl: string;
}

function ClientCard({imageUrl} : Prop) {
  return (
    <div className="h-full items-center rounded-sm scale-105">
      <img alt='client' className='w-full' src={imageUrl} />
    </div>
  );
}

export default ClientCard;

import igicon from '../images/igicon.png';
import xicon from '../images/xicon.png';
import linicon from '../images/linicon.png';
import fbicon from '../images/fbicon.png';
import addrsicon from '../images/addressicon.png';
import a4logo from '../images/a4logo.png';
import phnicon from '../images/phoneicon.png';
import glbicon from '../images/globe.png';
import mapicon from '../images/location.png';
import mailicon from '../images/gmailicon.png';

function Footer() {
  return (
    <div className='px-4 md:px-20 h-fit w-full bg-black py-7 md:py-20'>
      <div className='flex flex-col md:flex-row md:justify-between text-white mb-5'>
        <div className='flex basis-1/3 items-center mb-5'>
          <img alt="A4 Logo" className='mr-2 w-10 h-10' src={a4logo} />
          <h3 className='text-yellow-500 text-base font-semibold'>A4 Associates Consulting <br />Engineers Limited</h3>
        </div>
        <div className='md:hidden my-5'>
          <h1 className='block my-4'>Contact Us</h1>
          <div className='flex basis-1/3 space-x-6 items-center mb-5'>
            <img alt="address icon" className='rounded-full w-7 h-7 bg-white' src={glbicon} />
            <p className='font-normal text-sm'>info@a4associates.com.ng</p>
          </div>
          <div className='flex basis-1/3 space-x-6 items-center mb-5'>
            <img alt="address icon" className='rounded-full w-7 h-7 bg-white' src={phnicon} />
            <p className='font-normal text-sm'>0807 957 3978</p>
          </div>
          <div className='flex basis-1/3 space-x-6 items-center mb-5'>
            <img alt="address icon" className='rounded-full w-7 h-7 bg-white' src={phnicon} />
            <p className='font-normal text-sm'>09121111991</p>
          </div>
        </div>
        <div className='flex basis-1/3 space-x-6 items-center mb-5'>
          <div className='grid grid-cols-1 gap-y-4'>
            <div className='flex items-center space-x-2'>
              <img alt="address icon" className='rounded-full w-7 h-7 bg-white' src={mapicon} />
              <p className='font-normal text-sm'>No. 28 Arusha Crescent Wuse Zone 1, Abuja.</p>
            </div>
            <div className='flex items-center space-x-2'>
              <img alt="address icon" className='rounded-full w-7 h-7 bg-white' src={mapicon} />
              <p className='font-normal text-sm'>
                G.I
                Mega plaza, No 40/42 Lamido
                Ahmadu Road, Adamawa.
              </p>
            </div>
          </div>
        </div>
        <div className='invisible md:visible flex basis-1/3 space-x-6 items-center mb-5'>
          <div className='grid grid-cols-2 gap-x-20 gap-y-4'>
            <div className='flex items-center space-x-2'>
              <img alt="mail icon" className='grayscale rounded-full p-1 w-7 h-7 bg-white' src={mailicon} />
              <p className='font-normal text-sm'>info@a4associatesltd.com</p>
            </div>
            <div className='flex items-center space-x-2 justify-self-end'>
              <img alt="address icon" className='rounded-full w-7 h-7 bg-white' src={phnicon} />
              <p className='font-normal text-sm'>09121111991</p>
            </div>
            <div className='flex items-center space-x-2'>
              <img alt="address icon" className='rounded-full w-7 h-7 bg-white' src={phnicon} />
              <p className='font-normal text-sm'>0807 957 3978</p>
            </div>
          </div>
        </div>
      </div>
      <hr className='pb-5' />
      <div className='flex md:flex-row flex-col justify-between items-center py-5'>
        <h5 className='text-white text-center text-sm font-light my-3'>© 2024 A4 Associates Limited. All rights reserved.</h5>
        <div className='flex space-x-5 my-3'>
          <a href="http://www.instagram.com/a4associates_ltd" className='transition-all ease-in-out duration-100 hover:brightness-75 hover:scale-110 font-normal text-sm'><img alt="gmail icon" className='rounded-full w-7 h-7' src={igicon} /></a>
          <a href="http://www.x.com/A4ALimited" className='transition-all ease-in-out duration-100 hover:brightness-75 hover:scale-110 font-normal text-sm'><img alt="phone icon" className='rounded-full w-7 h-7' src={xicon} /></a>
          <a href="http://www.linkedin.com/company/a4-associates-limited" className='transition-all ease-in-out duration-100 hover:brightness-75 hover:scale-110 font-normal text-sm'><img alt="whatsapp icon" className='rounded-full w-7 h-7' src={linicon} /></a>
          <a href="https://web.facebook.com/profile.php?id=61560145374383" className='transition-all ease-in-out duration-100 hover:brightness-75 hover:scale-110 font-normal text-sm'><img alt="LinkedIn Icon" className='rounded-full w-7 h-7' src={fbicon} /></a>
        </div>
      </div>
    </div>
  )
}

export default Footer;
